import { useSelector } from "react-redux";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./components/tailwindComponents/Home";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import ReturnChapter from "./components/tailwindComponents/ReturnChapter";
import Navbar from "./components/NavBar"
import RNDChapter from "./components/tailwindComponents/RNDChapter";

const { REACT_APP_CREATE_AGREEMENT_API } = process.env
function App() {
  const state = useSelector((state) => state.stateReducer);


  return (
    <BrowserRouter>
      <Navbar />
      <ReactNotifications />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/return-chapter" element={<ReturnChapter />} />
        <Route path="/r&d-chapter" element={<RNDChapter />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
