export const formdata = [
    {
        name: "General:",
        group: [
            {
                name: "Period under review:",
                placeholder: "Describe the period under review (Example: FY 2014 - FY 2021)",
                id: "INPUT_REVIEWPERIOD",
                type: "text",
                element: "input-text",
                required: true,
                optional: false,
            },
            {
                name: "Company Name:",
                placeholder: "Mention here",
                id: "COMPANY",
                type: "text",
                element: "input-text",
                required: true,
                optional: false,
            },
        ]
    },
    {
        name: "Section I: Income Tax Returns",
        group: [
            {
                name: "Deadline for ITR submission:",
                group: [
                    {
                        name: "September 30",
                        element: "input-radio",
                        type: "radio",
                        nameAtt: "Deadline",
                        id: "sep",
                    },
                    {
                        name: "November 30 (if transfer pricing applicable)",
                        element: "input-radio",
                        type: "radio",
                        nameAtt: "Deadline",
                        id: "nov",
                    },
                    {
                        name: "Other",
                        element: "input-radio",
                        type: "radio",
                        nameAtt: "Deadline",
                        id: "oth",
                    },
                    {
                        // name: "Other",
                        placeholder: "Mention the deadline for ITR submission",
                        element: "input-text",
                        type: "text",
                        // id: "oth",
                        id: "INPUT_ITR_DEADLINE",
                        disabled: true,
                    },
                ],
            },
            {

                name: "ITR non-compliant years:",
                group: [
                    {
                        name: "None",
                        element: "input-radio",
                        type: "checkbox",
                        nameAtt: "ITR non-compliant years:",
                        id: "itrNCY",
                    },
                    {
                        name: "List out non-compliant years:",
                        placeholder: "Add FY separated by commas, example FY 2020, FY 2021",
                        element: "input-text",
                        type: "text",
                        // id: "oth",
                        id: "INPUT_ITR_NCFY",
                        // disabled: true,
                    },
                ],
            },
            {
                // head: "Section I: Income Tax Returns",
                name: "Any other issues pertaining to ITR compliance:",
                placeholder: "Mention here",
                id: "INPUT_ITR_OTHER",
                type: "text",
                element: "input-text",
                required: false,
                optional: false,
            },
        ]
    },
    {
        name: "Section II: Tax Audit Reports",
        group: [
            {
                name: "TAR non-compliant years:",
                group: [
                    {
                        name: "None",
                        element: "input-radio",
                        type: "checkbox",
                        nameAtt: "ITR non-compliant years:",
                        id: "tarNCYear",
                    },
                    {
                        name: "List out non-compliant years:",
                        placeholder: "Add FY separated by commas, example FY 2020, FY 2021",
                        element: "input-text",
                        type: "text",
                        // id: "oth",
                        id: "INPUT_TAR_NCFY",
                        // disabled: true,
                    },
                ]
            },
            {
                name: "Any other issues pertaining to TAR compliance:",
                placeholder: "Mention here",
                id: "INPUT_TAR_OTHER",
                type: "text",
                element: "input-text",
                required: false,
                optional: false,
            },
        ]
    },
    {
        name: "Section III: Withholding Tax",
        group: [
            {
                name: "WHT Non-Compliant Quarters",
                placeholder: "Mention here",
                id: "INPUT_WHT_PERIOD",
                type: "text",
                element: "input-text",
                required: false,
                optional: false,
            },
            {
                name: "Quantified WHT liability/fines, if any:",
                placeholder: "Mention the amount of fines that may be due by the Company due to default in WHT related matters",
                id: "INPUT_WHT_FINE",
                type: "text",
                element: "input-text",
                required: false,
                optional: false,
            },
            {
                name: "WHT - Any other issues:",
                placeholder: "Describe any other issues pertaining to WHT",
                id: "INPUT_WHT_OTHER",
                type: "text",
                element: "input-text",
                required: false,
                optional: false,
            },
        ]
    },
    {
        name: "Section IV: Transfer Pricing",
        group: [
            {
                name: "Whether TP provisions applicable",
                required: false,
                optional: false,
                group: [{
                    name: "Yes",
                    element: "input-radio",
                    type: "radio",
                    nameAtt: "Whether TP provisions applicable",
                    id: "TPYes",
                },
                {
                    name: "No",
                    element: "input-radio",
                    type: "radio",
                    nameAtt: "Whether TP provisions applicable",
                    id: "TPNo",
                },
                {
                    name: "Provide details:",
                    id: "INPUT_TP_NA",
                    type: "text",
                    element: "input-text",
                    placeholder: "Mention here",
                    required: false,
                    optional: false,
                    disabled: true,
                },
                ]
            },
            {
                name: "Deadline for TP Tax Report:",
                active: true,
                group: [
                    {
                        name: "November 30",
                        element: "input-radio",
                        type: "radio",
                        nameAtt: "Deadline for TP Tax Report:",
                        id: "deadNov",
                        active: true,
                    },
                    {
                        name: "Other",
                        element: "input-radio",
                        type: "radio",
                        nameAtt: "Deadline for TP Tax Report:",
                        id: "deadOth",
                        active: true,
                    },
                    {
                        // name: "Provide details:",
                        id: "INPUT_TP_DEADLINE",
                        type: "text",
                        element: "input-text",
                        placeholder: "Mention the deadline for TP Tax Report",
                        disabled: true,
                        active: true,
                    },
                ]
            },
            {

                name: "TP non-compliant years:",
                active: true,
                group: [
                    {
                        name: "None",
                        element: "input-radio",
                        type: "checkbox",
                        nameAtt: "Deadline for TP Tax Report:",
                        id: "TPNCY",
                        active: true,
                    },
                    {
                        name: "Mention non-compliant years:",
                        type: "text",
                        element: "input-text",
                        placeholder: "Add FY separated by commas, example FY 2020, FY 2021",
                        id: "INPUT_TP_NCFY",
                        active: true,

                    },
                ]
            },
            {
                name: "Any other issues pertaining to TP compliance:",
                placeholder: "Mention here",
                id: "INPUT_TP_OTHER",
                type: "text",
                element: "input",
                active: true,
            },
        ]
    },
]

export const fieldNames = ["INPUT_REVIEWPERIOD", "COMPANY", "INPUT_ITR_DEADLINE", "INPUT_ITR_NCFY", "INPUT_ITR_OTHER", "INPUT_TAR_NCFY", "INPUT_TAR_OTHER", "INPUT_WHT_PERIOD", "INPUT_WHT_NCFY", "INPUT_WHT_OTHER", "INPUT_WHT_FINE", "INPUT_TP_NA", "INPUT_TP_DEADLINE", "INPUT_TP_NCFY", "INPUT_TP_OTHER"]
