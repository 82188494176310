import React, { useState } from 'react';
import "../css/popup.css"
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';



const { REACT_APP_REDIRECT_URI } = process.env
const Popup = ({ message1, message2, mode, onConfirm, onCancel, onProConfirm, active }) => {
    const state = useSelector(state => state.stateReducer)
    const navigate = useNavigate()
    const [showPopup, setShowPopup] = useState(true);

    const handleConfirm = () => {
        setShowPopup(false);
        onConfirm();
    };

    const handleCancel = () => {
        setShowPopup(false);
        onCancel();
    };
    return (
        <>
            {showPopup && (
                <div className="popup-overlay alert alert-secondary" role="alert">
                    <div className="popup-container">
                        <div className="popup-message">{message1}</div>
                        <div className="popup-message mb-4">{message2}</div>
                        <div className="popup-actions">
                            <button className='btn btn-danger' onClick={handleCancel}> <i className='fas fa-times mr-2'></i>Not yet, go back</button>
                            <button className='btn btn-dark' onClick={handleConfirm}> <i className='fas fa-scroll mr-2'></i>Yes</button>
                        
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Popup;
