import React, { useEffect, useState } from "react";
import img from "../../assets/tipsytom.ico";
import "../css/home.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getUserDetails, getNotification } from "../../Redux/Action/action";
import Loader from "react-js-loader";
import { tabItem } from "../Agreement_Data/tabItem";
const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env;
const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [descriptionValue, setDescriptionValue] = useState(false);
  const [loader, setLoader] = useState(false);


  return (
    <div className="flex items-center justify-center flex-col min-h-screen">
      {
        loader ?
          <div className="m-8 my-20 max-w-[400px] mx-auto">
            <div className="mb-8">
              <div style={{ marginTop: "0vh" }} className="md:col-span-2 md:mt-0">
                <Loader
                  type="bubble-loop"
                  bgColor={"#000000"}
                  title={"Loading..."}
                  color={"#000000"}
                  size={100}
                />
              </div>
            </div>
          </div>
          :
          <div className="bg-[#F4F5FA] p-8 md:p-10 rounded-xl">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-12 justify-center items-center mt-3">
              {tabItem &&
                tabItem.map((ele, ind) => (
                  <div key={ind} className="bg-[#ECEEFF] rounded-xl">
                    <div className={`flex flex-col  h-50 px-8 py-6 rounded-xl ${ele.name === "Customised Templates" ? "bg-[#ffe4e1]" : ele.disabled ? "bg-[#e5e4e2]" : descriptionValue ? "bg-white" : ele.disabled && ele.paid ? "bg-[#e5e4e2]" : "bg-white"} shadow-xl translate-x-4 translate-y-4 w-auto h-80`}>
                      <div className="mt-2 font-semibold text-lg">{ele.name}</div>
                      <span style={{ marginTop: `${ele.margin1}` }}></span>
                      <div
                        className="text-md font-light w-60 md:w-auto"
                        style={{ fontSize: `17px` }}
                      >
                        {ele.description1}
                      </div>
                      <br />
                      {ele.description2 && (
                        <div
                          className="text-md font-light w-60 md:w-auto my-1 "
                        >
                          {ele.description2}
                        </div>
                      )}
                      <span style={{ marginTop: `${ele.margin3}` }}></span>
                      <button
                        disabled={loader ? true : false}
                        onClick={() => {
                          if (!descriptionValue) {
                            if (!ele.paid) {
                              navigate(ele.to);
                            }
                            else {
                              dispatch(
                                getNotification({
                                  message: "Subscribe to TipsyTom to get access to all features.",
                                  type: "default",
                                })
                              )
                              setTimeout(() => {
                                navigate("/app/subscription")
                              }, 3000)
                            }
                          }
                          else navigate(ele.to);
                        }}
                        className={ele.disabled ? "bg-[#F0F0F6] px-4 py-3 mt-2 rounded-full border border-[#F0F0F6] shadow-xl" : descriptionValue ? "bg-[#F0F0F6] custom-explore-button px-4 py-3 mt-2 rounded-full border border-[#F0F0F6] shadow-xl" : ele.disabled && ele.paid ? 'bg-[#F0F0F6] px-4 py-3 mt-2 rounded-full border border-[#F0F0F6] shadow-xl' : `bg-[#F4F5FA] custom-explore-button px-4 py-3 mt-2 rounded-full border border-[#F0F0F6] shadow-xl`}
                      >
                        {/* {loader ? <i className="custom-loader mx-2"></i> : ""} */}
                        {/* {<i className={loader ? "custom-loader mx-2" : "d-none"}></i>} */}
                        <span
                          style={{
                            fontSize: `${ele.buttonName.length >= 30
                              ?
                              "12px"
                              : ""
                              }`,
                          }}
                        >
                          {/* {loader ? (<i className="custom-loader mx-2"></i>) : ele.buttonName} */}
                          {ele.name === "Document Library" ? "Access your library here" : ele.buttonName}
                          <i
                            className="fas fa-arrow-right mx-2"
                          ></i>
                          {/* {descriptionValue ? "Start" : ind === 1 ? "Generate a notice now!" : "Generate a draft now!"} */}
                        </span>
                      </button>
                    </div>
                  </div>
                ))}
            </div>
            <br />
            <br />
            <br />
          </div>}
    </div >
  );
};

export default Home;
