import React, { useEffect, useState } from "react";
// import jQuery from "jquery";
import axios from "axios";
import Popup from "./Popup";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNotification, getUserDetails } from "../../Redux/Action/action";
import Loader from "react-js-loader";
import {
    formdata, fieldNames
} from "../Agreement_Data/R&DChapter";

const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env;

const RNDChapter = () => {
    const state = useSelector((state) => state.stateReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()
    const [checkBoxString, setCheckBoxString] = useState([]);
    // const [clause, setClause] = useState(true);
    const [createAgreement, setCreatAgreement] = useState("");
    const [contentState, setContentState] = useState(true);
    const [handleSubmit, setHandleSubmit] = useState(false);
    const [loaderAgreement, setLoaderAgreement] = useState(false);
    const [loaderDownload, setLoaderDownload] = useState(false);
    const [loaderCredit, setLoaderCredit] = useState(false);
    const [promt, setPromt] = useState(false)

    // const [other, setOther] = useState(false);
    // const [fileName, setFileName] = useState("");
    const [rename, setRename] = useState(false);
    const [renameString, setRenameString] = useState("New File");
    const [tagStore, setTagStore] = useState([]);
    const [handleStateOfTags, setHandleStateOfTags] = useState(false);


    const [fYear, setFYear] = useState([])
    const [form3cl, setForm3cl] = useState([])
    const [weightedDeduction, setWeightedDeduction] = useState([])
    const [approved, setApproved] = useState([])
    const [claimAmount, setclaimAmount] = useState([])
    const [approvedAmount, setApprovedAmount] = useState([])


    const [recognition, setRegognition] = useState(false)
    const [validClaim, setValidClaim] = useState(false)

    const [table, setTable] = useState([
        {
            FYear: "",
            form3CL: "",
            weightedDeductionClaimed: "",
            approved: "",
            claimAmount: "",
            approvedAmount: "",
        },
    ])
    useEffect(() => {
        resetButtonView()
        getDataFromStorage()
    }, []);



    useEffect(() => {
        const handleKeyPress = (event) => {
            saveDatatoStorage();
        };
        document.addEventListener('input', handleKeyPress);
        document.addEventListener('click', handleKeyPress);

        return () => {
            document.removeEventListener('input', handleKeyPress);
            document.removeEventListener('click', handleKeyPress);
        };
    }, [tagStore]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const getDataFromStorage = () => {
        const masterStorageObject = JSON.parse(localStorage.getItem("masterRNDChapterObject"))
        if (masterStorageObject) {
            setRenameString(masterStorageObject.renameString)
            setTagStore(masterStorageObject.tags)
            setRenameString(masterStorageObject.renameString)
            setTagStore(masterStorageObject.tags)
            fieldNames.forEach((fieldName) => {
                const field = document.getElementById(fieldName);
                if (field) {
                    field.value = masterStorageObject[fieldName] || '';
                }
            });
        }
    }
    const saveDatatoStorage = () => {
        const fileName = document.getElementById("input-file-name").value

        const masterStorageObject = {}

        fieldNames && fieldNames.forEach((fieldName) => {
            const field = document.getElementById(fieldName);
            const value = field ? field.value : "";
            masterStorageObject[fieldName] = value;
        });
        masterStorageObject.tags = tagStore;
        masterStorageObject.renameString = fileName;

        localStorage.setItem("masterRNDChapterObject", JSON.stringify(masterStorageObject))
    }


    const resetButtonView = () => {
        const floatEnd = document.querySelectorAll(".float-end")
        if (floatEnd.length != 0 && window.innerWidth <= "600") {
            floatEnd.forEach((e) => {
                e.classList.remove("float-end")
            })
        }
    }
    const preventFromCopyPaste = () => {
        const edit = document.getElementById("edit");
        if (edit) {
            edit.addEventListener("copy", preventDefault);
            edit.addEventListener("cut", preventDefault);

            function preventDefault(e) {
                e.preventDefault();
            }
        }
    };


    const getScrrenTOLoader = () => {
        var element = document.getElementById("my-agreement-spinner");
        if (element) {
            var offset = -200; // Adjust this value to set the desired offset

            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset + offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth" // Optionally, you can add smooth scrolling animation
            });
        }
    }
    const sendData = async (value) => {
        setTimeout(() => {
            getScrrenTOLoader()
        }, 100)

        const COMPANY = document.getElementById("COMPANY").value;
        const INPUT_REVIEWPERIOD = document.getElementById("INPUT_REVIEWPERIOD").value;
        const INPUT_FORM3CM = () => {
            if (document.getElementById("form3cmYes").checked) return "Yes"
            else return "No"
        }
        const INPUT_RECOGNITION = document.getElementById("INPUT_RECOGNITION").value ? document.getElementById("INPUT_RECOGNITION").value : "";
        const INPUT_MANAGEMENTREP = document.getElementById("INPUT_MANAGEMENTREP").value;



        for (let i = 0; i <= table.length - 1; i++) {
            let temp = fYear
            temp.push(document.getElementById("FYear_" + i).value)
            setFYear(temp)

            let temp2 = claimAmount
            temp2.push(document.getElementById("claimAmount_" + i).value ? document.getElementById("claimAmount_" + i).value : "")
            setclaimAmount(temp2.filter(value => value !== ''))

            let temp3 = approvedAmount
            temp3.push(document.getElementById("approvedAmount_" + i).value ? document.getElementById("approvedAmount_" + i).value : "")
            setApprovedAmount(temp3.filter(value => value !== ''))


            let temp4 = form3cl
            const getFrom3Cl = () => {
                if (document.getElementById("form3ClYes_" + i).checked === true) return "Yes"
                else return "No"

            }
            temp4.push(getFrom3Cl())
            setForm3cl(temp4)

            let temp5 = weightedDeduction
            const getweightedDeduction = () => {
                if (document.getElementById("weightedDeductionClaimedYes_" + i).checked === true) return "Yes"
                else if (document.getElementById("weightedDeductionClaimedNo_" + i).checked === true) return "No"
                else return ""
            }
            temp5.push(getweightedDeduction())
            setWeightedDeduction(temp5)


            let temp6 = approved
            const getapproved = () => {
                if (document.getElementById("approvedYes_" + i).checked === true) return "Yes"
                else if (document.getElementById("approvedNo_" + i).checked === true) return "No"
                else return ""
            }
            temp6.push(getapproved())
            setApproved(temp6)


        }

        const getClaimYear = () => {
            let temp = []
            weightedDeduction.forEach((ele, ind) => {
                if (ele === "Yes") temp.push(fYear[ind])
            })
            return temp.join(",")
        }
        const getNonclaimYear = () => {
            let temp = []
            weightedDeduction.forEach((ele, ind) => {
                if (ele === "No") temp.push(fYear[ind])
            })
            return temp.join(",")
        }
        const getAprroveYear = () => {
            let temp = []
            approved.forEach((ele, ind) => {
                if (ele === "Yes") temp.push(fYear[ind])
            })
            return temp.join(",")
        }
        const getNonApproveYear = () => {
            let temp = []
            approved.forEach((ele, ind) => {
                if (ele === "No") temp.push(fYear[ind])
            })
            return temp.join(",")
        }
        const getForm3CLYear = () => {
            let temp = []
            form3cl.forEach((ele, ind) => {
                if (ele === "Yes") temp.push(fYear[ind])
            })
            return temp.join(",")
        }
        if (COMPANY === "") {
            dispatch(
                getNotification({
                    message:
                        "Plesae fill the name of the entity under assessment.",
                    type: "info",
                })
            );
        }
        else if (INPUT_REVIEWPERIOD === "") {
            dispatch(
                getNotification({
                    message:
                        "Plesae enter the financial years for assessment.",
                    type: "info",
                })
            );
        }
        else if (INPUT_FORM3CM() === "Yes" && INPUT_RECOGNITION === "") {
            dispatch(
                getNotification({
                    message:
                        "Plesae enter the validity of the approval certificate.",
                    type: "info",
                })
            );
        }
        else {
            setHandleStateOfTags(true)
            try {
                setLoaderAgreement(true);
                const FormData = require("form-data");
                const sendData = new FormData();
                sendData.append("keycloak_user_id", "abcd");
                sendData.append("agreement_type", "RND Chapter");
                sendData.append("file_name", renameString);
                sendData.append("file_tags", tagStore.join(",").replace(/,$/, ''));
                sendData.append("api_type_alias", "Basic");
                sendData.append("INPUT_FORM3CM", INPUT_FORM3CM());
                sendData.append("INPUT_RECOGNITION", INPUT_RECOGNITION);
                sendData.append("COMPANY", COMPANY);
                sendData.append("INPUT_REVIEWPERIOD", INPUT_REVIEWPERIOD);
                sendData.append("INPUT_CLAIMYEAR", getClaimYear());
                sendData.append("INPUT_NONCLAIMYEAR", getNonclaimYear());
                sendData.append("INPUT_APPROVALYEAR", getAprroveYear());
                sendData.append("INPUT_NONAPPROVALYEAR", getNonApproveYear());
                sendData.append("INPUT_3CLYEAR", getForm3CLYear());
                let temp1 = claimAmount
                temp1 = temp1.filter(value => value !== '');
                sendData.append("INPUT_CLAIMAMOUNT", temp1.join("|"));

                let temp2 = approvedAmount
                temp2 = temp2.filter(value => value !== '');
                sendData.append("INPUT_APPROVEDAMOUNT", temp2.join("|"));
                sendData.append("INPUT_FORM3CL", form3cl.includes("Yes") ? "Yes" : "No");
                // sendData.append("INPUT_COMMENTS", INPUT_COMMENTS);
                sendData.append("INPUT_MANAGEMENTREP", INPUT_MANAGEMENTREP);


                const config = {
                    method: "post",
                    url: `${REACT_APP_CREATE_AGREEMENT_API}/ey_rnd_request`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    data: sendData,
                };
                setHandleSubmit(true);
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    const text = getResponse.data.agreement_text;
                    console.log(getResponse.data);
                    setCreatAgreement(text);
                    // getPlanDetails("noRefresh");
                    // console.log("Success");
                }
            } catch (err) {
                console.log("error", err);
                setHandleSubmit(false);
                setLoaderAgreement(false)
                // console.log("Error", err);
                dispatch(
                    getNotification({
                        message: "Sorry, our network is down with too many requests. Please try in sometime!",
                        type: "default",
                    })
                );
            }
            setLoaderAgreement(false);
            setFYear([])
            setForm3cl([])
            setWeightedDeduction([])
            setApproved([])
            setclaimAmount([])
            setApprovedAmount([])

            setTimeout(() => preventFromCopyPaste(), 100);
        }
    };

    const download = async () => {
        const text = document.getElementById("edit").innerText;
        try {
            const FormData = require("form-data");
            const sendData = new FormData();
            sendData.append("input_text", text);
            const config = {
                method: "post",
                url: `${REACT_APP_CREATE_AGREEMENT_API}/ey_export_as_docx`,
                responseType: "blob",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    // "access_token": UserService.getToken()
                },
                data: sendData,
            };
            setLoaderDownload(true);
            const getResponse = await axios(config);
            if (getResponse.status === 200) {
                const getCurrentDate = () => {
                    const currentDate = new Date();
                    const year = String(currentDate.getFullYear()).slice(-4);
                    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
                    const day = String(currentDate.getDate()).padStart(2, "0");
                    return `${year}-${day}-${month}`;
                };
                const blob = new Blob([getResponse.data], {
                    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = `R&D Chapter_${getCurrentDate()}.docx`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                dispatch(
                    getNotification({
                        message: "Downloaded successfully",
                        type: "success",
                    })
                );
            }
        } catch (err) {
            setLoaderDownload(false);
            console.log("Error", err);
            dispatch(
                getNotification({
                    message: "Network issue. Please click on the download button again..",
                    type: "default",
                })
            );
        }
        setLoaderDownload(false);
        setTimeout(() => preventFromCopyPaste(), 10);
    };

    const checkBoxStringMaker = (event, name) => {
        if (event.target.checked === true) {
            checkBoxString.push(name);
            setCheckBoxString([...checkBoxString]);
        } else {
            checkBoxString.pop(name);
            setCheckBoxString([...checkBoxString]);
        }
    };

    const resetForm = () => {
        fieldNames.forEach((fieldName) => {
            const field = document.getElementById(fieldName);
            if (field) {
                field.value = '';
            }
        });

        setRenameString("New File")
        let temp = []
        setTagStore(temp)
        setHandleStateOfTags(false)
    };


    const getTagButtonFromArray = () => {
        const tag = document.querySelector(".custom-input")
        let tempArr = [...tagStore]
        if (tag.value === "") {
            dispatch(
                getNotification({
                    message: "Please enter a tag.",
                    type: "default",
                })
            );
        }
        else {
            tempArr.push(tag.value)
        }
        setTagStore(tempArr);
        tag.value = ""
    }

    const removeTagFromArray = (value) => {
        let tempArr = [...tagStore]
        if (value) tempArr = tempArr.filter(e => e !== value)
        setTagStore(tempArr);
    }

    const handleConfirm = () => {
        sendData();
        setPromt(false)
    };
    const handleProConfirm = () => {
        sendData(true)
        setPromt(false)
    }
    const handleCancel = () => {
        setPromt(false)
    };


    return (
        <div id="contract" className="container-fluid contract">
            <div className="alert alert-secondary p-0 m-0" role="alert">
                <h3 className='text-center'>R&D Chapter</h3>
            </div>
            {loaderCredit ? (
                <div style={{ marginTop: "30vh" }} className="md:col-span-2 md:mt-0">
                    <Loader
                        type="bubble-spin"
                        bgColor={"#000000"}
                        title={"Loading..."}
                        color={"#000000"}
                        size={100}
                    />
                </div>
            ) : (
                <div className="contractForm mt-1">
                    <div>
                        <div className="md:grid md:grid-cols-4 md:gap-6">
                            <div
                                style={{
                                    // height: "1353px",
                                }}
                                className={
                                    handleSubmit ? "md:col-span-2" : "md:col-span-4 container"
                                }
                            >
                                {/* <form> */}
                                <div className="shadow sm:overflow-hidden sm:rounded-md p-1">
                                    <div className="menu-bar mt-1">
                                        <button className="btn btn-success ml-3">
                                            <input
                                                id="input-file-name"
                                                className={rename ? "" : "d-none"}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") setRename(false);
                                                }}
                                                onChange={(e) => {
                                                    setRenameString(e.target.value);
                                                }}
                                                defaultValue={renameString}
                                                type="text"
                                                style={{
                                                    width: "150px",
                                                    height: "30px",
                                                    color: "black",
                                                }}
                                            />
                                            <span
                                                onClick={() => {
                                                    if (rename) setRename(false);
                                                    else setRename(true);
                                                }}
                                                id="file-name" spiketip-title={`${renameString}`} spiketip-pos={renameString.length > 20 ? "right" : "bottom"} className={rename ? "d-none" : ""}>
                                                {renameString.length > 20 ? `${renameString.slice(0, 20)}...` : renameString}
                                                <span className="ml-2">|</span>
                                            </span>
                                            <i
                                                onClick={() => {
                                                    if (rename) setRename(false);
                                                    else setRename(true);
                                                }}
                                                className={
                                                    rename ? "fas fa-check mx-3" : "fas fa-edit ml-3"
                                                }
                                            ></i>
                                            {/* <i className="fas fa-times"></i> */}
                                        </button>

                                        <button
                                            spiketip-title="Reset Form" spiketip-pos="bottom"
                                            onClick={() => {
                                                resetForm();
                                            }}
                                            className="btn btn-dark float-end"
                                        >
                                            <i className="fas fa-redo"></i>
                                        </button>
                                        <button
                                            spiketip-title={handleSubmit ? `${tagStore.length} tag${tagStore.length >= 2 ? "s" : ""} added.` : "Add Tag"} spiketip-pos="bottom"
                                            onClick={() => getTagButtonFromArray()}
                                            id="add-tag"
                                            className="btn btn-dark round-btn mx-2 mt-0.5 float-end"
                                        >
                                            {handleSubmit ?
                                                <span className="-mx-2 fw-bolder">
                                                    {tagStore.length}
                                                </span> :
                                                <i className="fas fa-plus plus-position"></i>
                                            }
                                        </button>
                                        <input
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    getTagButtonFromArray(e.target.value)
                                                }
                                            }}
                                            placeholder="Add tag here"
                                            className={handleStateOfTags ? "d-none" : "px-1 mt-1.5 rounded btn custom-input float-end"}
                                            style={{
                                                height: "30px",
                                                // fontSize: handleSubmit ? "14px" : "",
                                                width: "110px",
                                                border: "2px solid",
                                                textAlign: "left",
                                            }}
                                            type="text"
                                        />
                                        <span className="scrollable-span mx-2 mt-2 float-end pb-1">
                                            {tagStore.length > 0 &&
                                                !handleSubmit &&
                                                tagStore.map((ele, ind) => (
                                                    <button
                                                        key={ind}
                                                        className="btn btn-dark ml-2 p-0 px-1 position-relative"
                                                        onMouseEnter={(e) => {
                                                            const iconElement = e.target.querySelector('i');
                                                            iconElement && iconElement.classList.remove("d-none")
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            const iconElement = e.target.querySelector('i');
                                                            iconElement && iconElement.classList.add("d-none")
                                                        }}
                                                    >
                                                        {ele}
                                                        <i
                                                            // style={{ display: "none" }}
                                                            onClick={() => { removeTagFromArray(ele) }}
                                                            className="fas fa-window-close ml-1 d-none"></i>
                                                    </button>
                                                ))}
                                        </span>
                                    </div>
                                    <hr className="mt-2 mb-0" />
                                    <div className="space-y-6 bg-white sm:p-4">
                                        <div className="grid grid-cols-3 gap-1">
                                            {formdata.map((e, i) => {
                                                if (e.element === "input") {
                                                    return (
                                                        <div key={i} className={"col-span-6 sm:col-span-3"}>
                                                            <label htmlFor={e.id} className={`block text-sm font-medium text-gray-700 ${e.id === "INPUT_RECOGNITION" ? recognition ? "" : "text-secondary" : ""}`}>
                                                                {e.name} <span className={e.required ? "text-danger" : "d-none"}>*</span>
                                                            </label>
                                                            <input
                                                                disabled={e.id === "INPUT_RECOGNITION" && !recognition ? true : false}
                                                                type={e.type ? e.type : "text"}
                                                                placeholder={e.placeholder}
                                                                name={e.id}
                                                                id={e.id}
                                                                autoComplete="given-name"
                                                                className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${e.id === "INPUT_RECOGNITION" ? recognition ? "" : "btn-secondary" : ""}`}
                                                            />
                                                        </div>
                                                    );
                                                }
                                                else if (e.element === "input-checboxes") {
                                                    return (
                                                        <div key={i} className="col-span-6 sm:col-span-3">
                                                            <label htmlFor={e.id} className="block text-sm font-medium text-gray-700">
                                                                {e.name} <span className={e.required ? "text-danger" : "d-none"}>*</span>
                                                            </label>
                                                            <div className="flex">
                                                                <div className="flex mx-2">
                                                                    <label htmlFor={"form3cmYes"} className="mx-2 block text-sm font-medium text-gray-700 mt-2">
                                                                        Yes
                                                                    </label>
                                                                    <input
                                                                        type="radio"
                                                                        name={e.id}
                                                                        id="form3cmYes"
                                                                        autoComplete="given-name"
                                                                        className="mt-2 block"
                                                                        onClick={() => setRegognition(true)}
                                                                    />
                                                                </div>
                                                                <div className="flex mx-2">
                                                                    <label htmlFor={"form3cmNo"} className="mx-2 block text-sm font-medium text-gray-700 mt-2">
                                                                        No
                                                                    </label>
                                                                    <input
                                                                        type="radio"
                                                                        name={e.id}
                                                                        id="form3cmNo"
                                                                        autoComplete="given-name"
                                                                        className="mt-2 block"
                                                                        onClick={() => setRegognition(false)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                else if (e.element === "table-input") {
                                                    return (
                                                        <div key={i} className="col-span-6 sm:col-span-3 mt-1 overflow-x-auto">
                                                            <fieldset>
                                                                <div
                                                                    className="text-sm font-semibold text-grey-900"
                                                                    aria-hidden="true"
                                                                >
                                                                    {e.name}
                                                                </div>
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">Financial Year</th>
                                                                            <th scope="col">Weighted Deduction Claimed</th>
                                                                            <th scope="col">Form 3CL</th>
                                                                            <th scope="col">Approved</th>
                                                                            <th scope="col">Claim amount <br /> (INR in crores)</th>
                                                                            <th scope="col">Approved amount <br />(INR in crores)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            table && table.map((ele, ind) => (
                                                                                <tr key={ind} >
                                                                                    <th scope="row">
                                                                                        <input
                                                                                            type="text"
                                                                                            name='Financial Year'
                                                                                            id={"FYear_" + ind}
                                                                                            autoComplete="given-name"
                                                                                            className="mt-1 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                        />
                                                                                    </th>
                                                                                    <td>
                                                                                        <div className="flex">
                                                                                            <div className="flex">
                                                                                                <label htmlFor={"weightedDeductionClaimedYes_" + ind} className="block text-sm font-medium text-gray-700 mt-2">
                                                                                                    Yes
                                                                                                </label>
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    // placeholder={e.placeholder}
                                                                                                    name={"weightedDeductionClaimed_" + ind}
                                                                                                    id={"weightedDeductionClaimedYes_" + ind}
                                                                                                    autoComplete="given-name"
                                                                                                    className="mt-2.5 mx-2"
                                                                                                    onClick={() => {
                                                                                                        document.getElementById("claimAmount_" + ind).disabled = false
                                                                                                        document.getElementById("claimAmount_" + ind).classList.remove("btn-secondary")


                                                                                                        document.querySelector(".form3ClYes_" + ind).classList.remove("text-secondary")
                                                                                                        document.getElementById("form3ClYes_" + ind).classList.remove("btn-outline-secondary")
                                                                                                        document.getElementById("form3ClYes_" + ind).disabled = false

                                                                                                        document.querySelector(".form3ClNo_" + ind).classList.remove("text-secondary")
                                                                                                        document.getElementById("form3ClNo_" + ind).classList.remove("btn-outline-secondary")
                                                                                                        document.getElementById("form3ClNo_" + ind).disabled = false

                                                                                                        document.querySelector(".approvedYes_" + ind).classList.remove("text-secondary")
                                                                                                        document.getElementById("approvedYes_" + ind).classList.remove("btn-outline-secondary")
                                                                                                        document.getElementById("approvedYes_" + ind).disabled = false

                                                                                                        document.querySelector(".approvedNo_" + ind).classList.remove("text-secondary")
                                                                                                        document.getElementById("approvedNo_" + ind).classList.remove("btn-outline-secondary")
                                                                                                        document.getElementById("approvedNo_" + ind).disabled = false


                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="flex">
                                                                                                <label htmlFor={"weightedDeductionClaimedNo_" + ind} className="block text-sm font-medium text-gray-700 mt-2">
                                                                                                    No
                                                                                                </label>
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    // placeholder={e.placeholder}
                                                                                                    name={"weightedDeductionClaimed_" + ind}
                                                                                                    id={"weightedDeductionClaimedNo_" + ind}
                                                                                                    autoComplete="given-name"
                                                                                                    className="mt-2.5 mx-2"
                                                                                                    onClick={() => {
                                                                                                        document.getElementById("claimAmount_" + ind).disabled = true
                                                                                                        document.getElementById("claimAmount_" + ind).classList.add("btn-secondary")
                                                                                                        document.getElementById("approvedAmount_" + ind).classList.add("btn-secondary")


                                                                                                        document.querySelector(".form3ClYes_" + ind).classList.add("text-secondary")
                                                                                                        document.getElementById("form3ClYes_" + ind).classList.add("btn-outline-secondary")
                                                                                                        document.getElementById("form3ClYes_" + ind).disabled = true

                                                                                                        // document.getElementById("form3ClNo_" + ind).click()
                                                                                                        document.querySelector(".form3ClNo_" + ind).classList.add("text-secondary")
                                                                                                        // document.getElementById("form3ClNo_" + ind).classList.add("btn-outline-secondary")
                                                                                                        document.getElementById("form3ClNo_" + ind).disabled = true

                                                                                                        document.querySelector(".approvedYes_" + ind).classList.add("text-secondary")
                                                                                                        document.getElementById("approvedYes_" + ind).classList.add("btn-outline-secondary")
                                                                                                        document.getElementById("approvedYes_" + ind).disabled = true

                                                                                                        // document.getElementById("approvedNo_" + ind).click()
                                                                                                        document.querySelector(".approvedNo_" + ind).classList.add("text-secondary")
                                                                                                        // document.getElementById("approvedNo_" + ind).classList.add("btn-outline-secondary")
                                                                                                        document.getElementById("approvedNo_" + ind).disabled = true


                                                                                                        document.getElementById("claimAmount_" + ind).value = ""
                                                                                                        document.getElementById("approvedAmount_" + ind).value = ""


                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="flex">
                                                                                            <div className="flex">
                                                                                                <label htmlFor={"form3ClYes_" + ind} className={"block text-sm font-medium text-gray-700 mt-2 text-secondary form3ClYes_" + ind}>
                                                                                                    Yes
                                                                                                </label>
                                                                                                <input
                                                                                                    disabled={true}
                                                                                                    type="radio"
                                                                                                    name={"Form3CL_" + ind}
                                                                                                    id={"form3ClYes_" + ind}
                                                                                                    autoComplete="given-name"
                                                                                                    className="mt-2.5 mx-2 btn-outline-secondary"
                                                                                                />
                                                                                            </div>
                                                                                            <div className="flex">
                                                                                                <label htmlFor={"form3ClNo_" + ind} className={"block text-sm font-medium text-gray-700 mt-2 text-secondary form3ClNo_" + ind}>
                                                                                                    No
                                                                                                </label>
                                                                                                <input
                                                                                                    disabled={true}
                                                                                                    type="radio"
                                                                                                    name={"Form3CL_" + ind}
                                                                                                    id={"form3ClNo_" + ind}
                                                                                                    autoComplete="given-name"
                                                                                                    className="mt-2.5 mx-2 btn-outline-secondary"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="flex">
                                                                                            <div className="flex">
                                                                                                <label htmlFor={"approvedYes_" + ind} className={"block text-sm font-medium text-gray-700 mt-2 text-secondary approvedYes_" + ind}>
                                                                                                    Yes
                                                                                                </label>
                                                                                                <input
                                                                                                    disabled={true}
                                                                                                    type="radio"
                                                                                                    // placeholder={e.placeholder}
                                                                                                    name={"approved_" + ind}
                                                                                                    id={"approvedYes_" + ind}
                                                                                                    autoComplete="given-name"
                                                                                                    className="mt-2.5 mx-2 btn-outline-secondary"
                                                                                                    onChange={() => {
                                                                                                        document.getElementById("approvedAmount_" + ind).disabled = false
                                                                                                        document.getElementById("approvedAmount_" + ind).classList.remove("btn-secondary")
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="flex">
                                                                                                <label htmlFor={"approvedNo_" + ind} className={"block text-sm font-medium text-gray-700 mt-2 text-secondary approvedNo_" + ind}>
                                                                                                    No
                                                                                                </label>
                                                                                                <input
                                                                                                    disabled={true}
                                                                                                    type="radio"
                                                                                                    // placeholder={e.placeholder}
                                                                                                    name={"approved_" + ind}
                                                                                                    id={"approvedNo_" + ind}
                                                                                                    autoComplete="given-name"
                                                                                                    className="mt-2.5 mx-2 btn-outline-secondary"
                                                                                                    onChange={() => {
                                                                                                        document.getElementById("approvedAmount_" + ind).disabled = true
                                                                                                        document.getElementById("approvedAmount_" + ind).classList.add("btn-secondary")
                                                                                                        document.getElementById("approvedAmount_" + ind).value = 0

                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <input
                                                                                            type="number"
                                                                                            min="0"
                                                                                            // placeholder={e.placeholder}
                                                                                            // name={}
                                                                                            id={"claimAmount_" + ind}
                                                                                            disabled={true}
                                                                                            autoComplete="given-name"
                                                                                            className="mt-1 block w-2/3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <input
                                                                                            type="number"
                                                                                            min="0"
                                                                                            // placeholder={e.placeholder}
                                                                                            // name={}
                                                                                            disabled={true}
                                                                                            id={"approvedAmount_" + ind}
                                                                                            autoComplete="given-name"
                                                                                            className={`mt-1 block w-2/3 rounded-md border-gray-300 shadow-sm focus:border-${validClaim ? "red-500 border-4" : "indigo-500"} focus:ring-${validClaim ? "red" : "indigo"}-500 sm:text-sm`}
                                                                                            onChange={(event) => {
                                                                                                const approvedAmount = parseFloat(event.target.value);
                                                                                                const claimAmount = parseFloat(document.getElementById("claimAmount_" + ind).value);
                                                                                                if (claimAmount >= approvedAmount) {
                                                                                                    setValidClaim(false)
                                                                                                } else {
                                                                                                    setValidClaim(true)
                                                                                                    dispatch(
                                                                                                        getNotification({
                                                                                                            message:
                                                                                                                "Approved amount should be greater than eqaul to claim amount.",
                                                                                                            type: "default",
                                                                                                        })
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </fieldset>
                                                            <div className="text-center">
                                                                <button
                                                                    className="btn btn-warning"
                                                                    onClick={() => {
                                                                        const tempTable = table
                                                                        tempTable.forEach((ele, ind) => {
                                                                            ele.FYear = document.getElementById("FYear_" + ind).value

                                                                            ele.claimAmount = document.getElementById("claimAmount_" + ind).value
                                                                            // setclaimAmount([...claimcAmount,ele.])
                                                                            ele.approvedAmount = document.getElementById("approvedAmount_" + ind).value
                                                                            ele.form3CL = () => {
                                                                                if (document.getElementById("form3CLYes_" + ind).checked = true) return "Yes"
                                                                                else if (document.getElementById("form3CLNo_" + ind).checked = true) return "No"
                                                                                else return "not selected"
                                                                            }
                                                                            ele.weightedDeductionClaimed = () => {
                                                                                if (document.getElementById("weightedDeductionClaimedYes_" + ind).checked = true) return "Yes"
                                                                                else if (document.getElementById("weightedDeductionClaimedNo_" + ind).checked = true) return "No"
                                                                                else return "not selected"
                                                                            }
                                                                        })
                                                                        setTable(tempTable)
                                                                        const temp = {
                                                                            FYear: "",
                                                                            form3CL: "",
                                                                            weightedDeductionClaimed: "",
                                                                            approved: "",
                                                                            claimAmount: "",
                                                                            approvedAmount: "",
                                                                        }
                                                                        setTable([...table, temp])



                                                                    }}

                                                                >Add another row
                                                                </button>

                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                else {
                                                    return null; // Return null or handle other element types here
                                                }
                                            })}
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            {promt && <Popup
                                                message1="Would you like to go ahead and generate the chapter?"
                                                mode={false}
                                                onConfirm={handleConfirm}
                                                onProConfirm={handleProConfirm}
                                                onCancel={handleCancel} />}
                                            <button
                                                disabled={validClaim ? true : false}
                                                spiketip-title="Submit Form" spiketip-pos="top"
                                                type="submit"
                                                className="btn btn-success mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                onClick={() => { setPromt(true) }}
                                            >
                                                Submit
                                                <i className="fas fa-upload mx-2"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* </form> */}
                            </div>
                            {loaderAgreement ? (
                                <div
                                    id="my-agreement-spinner"
                                    style={{ marginTop: "30vh" }}
                                    className="md:col-span-2 md:mt-0"
                                >
                                    <Loader
                                        type="bubble-ping"
                                        bgColor={"#000000"}
                                        title={
                                            "Loading.."
                                        }
                                        color={"#000000"}
                                        size={100}
                                    />
                                    <br />
                                    <br />
                                    {/* <h5 className={document.getElementById("toggle-button").classList.contains("active") ? "d-none" : "font-italic text-center mt-5"}> The strongest of all warriors are these two - time and patience</h5>
                  <br />
                  <figcaption className={document.getElementById("toggle-button").classList.contains("active") ? "d-none" : "blockquote-footer mb-0 font-italic text-end"}>
                    Mark Twain
                  </figcaption> */}

                                    <h5 className="text-center mt-5">Warning: This is a preliminary and work-in-progress Scripter prepared exclusively for the purpose of POC. This is a concept demonstration Scripter and does not represent the final features,design or functionality of the product. The Scripter is to be used for the limited purpose of testing and you may not rely on this for generating reports in live client matters such as advisory or due diligence work. </h5>
                                </div>
                            ) : loaderDownload ? (
                                <div
                                    style={{ marginTop: "30vh" }}
                                    className="md:col-span-2 md:mt-0"
                                >
                                    <Loader
                                        type="box-rotate-z"
                                        bgColor={"#000000"}
                                        title={"Downloading.."}
                                        color={"#000000"}
                                        size={50}
                                    />
                                </div>
                            ) : (
                                <div
                                    className={handleSubmit ? "md:col-span-2 md:mt-0" : "d-none"}
                                >
                                    <div className="sm:px-1 shadow sm:overflow-hidden sm:rounded-md py-1 px-1">
                                        <div className="menu-bar mt-1">
                                            <span className="scrollable-span-right pb-1">
                                                {tagStore.length > 0 &&
                                                    handleSubmit && handleStateOfTags &&
                                                    tagStore.map((ele, ind) => (
                                                        <button key={ind} className="btn btn-dark ml-1 p-0 px-1">
                                                            {ele}
                                                        </button>
                                                    ))}
                                            </span>
                                            <button
                                                spiketip-title={contentState ? "Edit Content" : "Save Conetent"} spiketip-pos="bottom"
                                                className="btn btn-secondary mx-4"
                                                onClick={() => {

                                                    setContentState(!contentState);
                                                    setTimeout(() => preventFromCopyPaste(), 10);
                                                    if (contentState) {
                                                        document.getElementById(
                                                            "edit"
                                                        ).contentEditable = true;
                                                        document
                                                            .getElementById("download")
                                                            .setAttribute("disabled", true);
                                                    } else {
                                                        document.getElementById(
                                                            "edit"
                                                        ).contentEditable = false;
                                                        document
                                                            .getElementById("download")
                                                            .removeAttribute("disabled");
                                                    }

                                                    // else dispatch(
                                                    //   getNotification({
                                                    //     message:
                                                    //       "Plesae sign up or log in to edit the document.",
                                                    //     type: "info",
                                                    //   })
                                                    // );
                                                }}
                                            >
                                                <i
                                                    id="icon"
                                                    className={
                                                        contentState ? "fas fa-edit" : "fas fa-save"
                                                    }
                                                ></i>
                                            </button>
                                            <button
                                                spiketip-title="Download Chapter" spiketip-pos="bottom"
                                                id="download"
                                                title="Download Chapter"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    download();
                                                    // else dispatch(
                                                    //   getNotification({
                                                    //     message:
                                                    //       "Plesae sign up or log in to download the document.",
                                                    //     type: "info",
                                                    //   })
                                                    // );
                                                }}
                                            >
                                                <i className="fas fa-download"></i>
                                            </button>
                                        </div>
                                        <hr className="mt-2" />

                                        <div className="px-3">
                                            <div
                                                onClick={() => console.log((Object.entries(table)).length)}
                                                id="edit"
                                                contentEditable={false}
                                                style={{
                                                    height: `${638 + 67 * ((Object.entries(table)).length - 1)}px`,
                                                    overflow: "auto",
                                                }}
                                                className="mt-1 text-lg text-gray-600 whitespace-pre-wrap px-1"
                                            >

                                                {
                                                    createAgreement && createAgreement.map((ele, ind) => (
                                                        <div className="" key={ind}>
                                                            <div className={ele.content_name.includes("HEADING") ? "text-xl" : "d-none"}><strong>{ele.content_text}</strong></div>
                                                            <div className={ele.content_name.includes("HEADING") ? "d-none" : "px-2 my-2"} style={{ fontSize: "15px" }}>{ele.content_text}</div>
                                                        </div>
                                                    ))
                                                }
                                                <br />
                                            </div>
                                            <button
                                                spiketip-title="Download Chapter" spiketip-pos="top"
                                                className={`btn btn-success mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mb-3`}
                                                onClick={() => {
                                                    download();
                                                }}
                                            >
                                                Download Chapter{" "}
                                                <i className="fas fa-download mx-1"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <br /><br /><br /><br /><br />
                    </div>
                </div>
            )
            }
        </div >
    );
};

export default RNDChapter;
