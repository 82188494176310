import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import "./css/style.css";
import tipsytom from "../assets/tipsy1.png"
import "./css/navbar.css"
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";


function NavBar() {
  const state = useSelector((state) => state.stateReducer);
  const navigate = useNavigate()
  const [error, setError] = useState(false);


  return (
    <div className="navbar sticky-top">
      <div className="logo">
        <button
        >
          <img style={{ width: "150px" }} src={tipsytom} alt="tipsytom-logo" />
          {/* <img src={logo} style={{ width: "100px" }} alt="logo" /> */}
        </button>
      </div>
      <ul className="nav-links ">
        <Link to="/" className="btn btn-dark text-light">Home</Link>
      </ul>
    </div>
  );

}

export default NavBar;

