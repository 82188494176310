export const tabItem = [
    {
        name: "Returns Chapter",
        description1:
            "Due Diligence Report: Tax Audit, Filings and Returns",
        description2:
            "Scripter to prepare the long-form text of Chapter 3 of the Due Diligence Report on Tax Audit, Fillings and Returns.",
        buttonName: "Start",
        to: "/return-chapter",
        margin1: "30px",
        margin3: "45px",
    },
    {
        name: "R&D Chapter",
        description1:
            "Due Diligence Report: R&D expenses - weighted deduction",
        description2:
            " Scripter to prepare the long-form text of Chapter on R&D expenses of the Due Diligence Report on Tax Audit, Filings and Returns. ",
        buttonName: "Start",
        to: "/r&d-chapter",
        margin1: "10px",
        margin3: "10px",
    },
];