export const formdata = [
    {
        name: "Name of the entity under assessment.",
        placeholder: "Mention here",
        id: "COMPANY",
        type: "text",
        element: "input",
        required: true,
        optional: false,
    },
    {
        name: "Enter the financial years for assessment.",
        placeholder: "Mention here",
        id: "INPUT_REVIEWPERIOD",
        type: "text",
        element: "input",
        required: true,
        optional: false,
    },
    {
        name: "Is the approval certificate in the form 3CM available?",
        // placeholder: "Mention here",
        id: "INPUT_FORM3CM",
        type: "",
        element: "input-checboxes",
        required: true,
        optional: false,
    },
    {
        name: "Enter the validity of the approval certificate.",
        placeholder: "Mention here",
        id: "INPUT_RECOGNITION",
        type: "text",
        element: "input",
        required: true,
        optional: false,
    },
    {
        name: "Please provide the following details:",
        placeholder: "",
        id: "",
        // type: "date",
        element: "table-input",
        required: false,
        optional: false,
    },
    {
        name: "Management representation",
        placeholder: "Mention here",
        id: "INPUT_MANAGEMENTREP",
        element: "input",
        type: "text",
        required: false,
        optional: false,
    },

]

export const fieldNames = ["COMPANY", "INPUT_REVIEWPERIOD", "INPUT_FORM3CM", "INPUT_RECOGNITION", "INPUT_MANAGEMENTREP"]


