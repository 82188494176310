import React, { useEffect, useState } from "react";
// import jQuery from "jquery";
import axios from "axios";
import Popup from "./Popup";
import "../css/draftAgreement.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNotification, getUserDetails } from "../../Redux/Action/action";
import Loader from "react-js-loader";
import {
    formdata, fieldNames
} from "../Agreement_Data/ReturnChapterData";

const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env;

const ReturnChapter = () => {
    const state = useSelector((state) => state.stateReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()




    const [checkBoxString, setCheckBoxString] = useState([]);
    // const [clause, setClause] = useState(true);
    const [createAgreement, setCreatAgreement] = useState("");
    const [contentState, setContentState] = useState(true);
    const [handleSubmit, setHandleSubmit] = useState(false);
    const [loaderAgreement, setLoaderAgreement] = useState(false);
    const [loaderDownload, setLoaderDownload] = useState(false);
    const [loaderCredit, setLoaderCredit] = useState(false);
    const [promt, setPromt] = useState(false)

    // const [other, setOther] = useState(false);
    // const [fileName, setFileName] = useState("");
    const [rename, setRename] = useState(false);
    const [renameString, setRenameString] = useState("New File");
    const [tagStore, setTagStore] = useState([]);
    const [handleStateOfTags, setHandleStateOfTags] = useState(false);



    const [activate, setActivate] = useState(true);


    useEffect(() => {
        resetButtonView()
        getDataFromStorage()
        getDefaultClicked()
    }, []);


    const getDefaultClicked = () => {
        setTimeout(() => document.getElementById("sep").click(), 100)
    }


    useEffect(() => {
        const handleKeyPress = (event) => {
            saveDatatoStorage();
        };
        document.addEventListener('input', handleKeyPress);
        document.addEventListener('click', handleKeyPress);

        return () => {
            document.removeEventListener('input', handleKeyPress);
            document.removeEventListener('click', handleKeyPress);
        };
    }, [tagStore]);


    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [])

    const getDataFromStorage = () => {
        const masterStorageObject = JSON.parse(localStorage.getItem("masterReturnChapterObject"))
        if (masterStorageObject) {
            setRenameString(masterStorageObject.renameString)
            setTagStore(masterStorageObject.tags)
            setRenameString(masterStorageObject.renameString)
            setTagStore(masterStorageObject.tags)
            fieldNames.forEach((fieldName) => {
                const field = document.getElementById(fieldName);
                if (field) {
                    field.value = masterStorageObject[fieldName] || '';
                }
            });

            // if (masterStorageObject.mode === "Pro") toggleSwitch()
        }
    }
    const saveDatatoStorage = () => {
        const fileName = document.getElementById("input-file-name").value

        const masterStorageObject = {}

        fieldNames && fieldNames.forEach((fieldName) => {
            const field = document.getElementById(fieldName);
            const value = field ? field.value : "";
            masterStorageObject[fieldName] = value;
        });
        masterStorageObject.tags = tagStore;
        masterStorageObject.renameString = fileName;

        localStorage.setItem("masterReturnChapterObject", JSON.stringify(masterStorageObject))
    }



    const resetButtonView = () => {
        const floatEnd = document.querySelectorAll(".float-end")
        if (floatEnd.length != 0 && window.innerWidth <= "600") {
            floatEnd.forEach((e) => {
                e.classList.remove("float-end")
            })
        }
    }
    const preventFromCopyPaste = () => {
        const edit = document.getElementById("edit");
        if (edit) {
            edit.addEventListener("copy", preventDefault);
            edit.addEventListener("cut", preventDefault);

            function preventDefault(e) {
                e.preventDefault();
            }
        }
    };


    const getScrrenTOLoader = () => {
        var element = document.getElementById("my-agreement-spinner");
        var offset = -200; // Adjust this value to set the desired offset
        if (element) {

            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset + offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth" // Optionally, you can add smooth scrolling animation
            });
        }
    }
    const sendData = async (value) => {
        setTimeout(() => {
            getScrrenTOLoader()
        }, 100)

        const INPUT_REVIEWPERIOD = document.getElementById("INPUT_REVIEWPERIOD").value;
        const COMPANY = document.getElementById("COMPANY").value;

        const INPUT_ITR_DEADLINE = () => {
            if (document.getElementById("oth").checked === true && document.getElementById("INPUT_ITR_DEADLINE").value) return document.getElementById("INPUT_ITR_DEADLINE").value
            else if (document.getElementById("sep").checked === true) return "September 30"
            else if (document.getElementById("nov").checked === true) return "November 30"
            else return ""
        }



        const INPUT_ITR_NCFY = () => {
            if (document.getElementById("INPUT_ITR_NCFY").value) return document.getElementById("INPUT_ITR_NCFY").value
            else if (document.getElementById("itrNCY").checked === true) return ""
            else return ""
        }

        const INPUT_ITR_OTHER = document.getElementById("INPUT_ITR_OTHER").value;

        const INPUT_TAR_NCFY = () => {
            if (document.getElementById("INPUT_TAR_NCFY").value) return document.getElementById("INPUT_TAR_NCFY").value
            else if (document.getElementById("tarNCYear").checked === true) return ""
            else return ""
        }



        const INPUT_TAR_OTHER = document.getElementById("INPUT_TAR_OTHER").value;
        const INPUT_WHT_PERIOD = document.getElementById("INPUT_WHT_PERIOD").value;



        const INPUT_WHT_OTHER = document.getElementById("INPUT_WHT_OTHER").value;
        const INPUT_WHT_FINE = document.getElementById("INPUT_WHT_FINE").value;

        const INPUT_TP_NA = () => {
            if (document.getElementById("INPUT_TP_NA").value) return document.getElementById("INPUT_TP_NA").value
            else if (document.getElementById("TPYes").checked === true) return "Yes"
            else if (document.getElementById("TPNo").checked === true) return document.getElementById("INPUT_TP_NA").value
            else return ""
        }


        const INPUT_TP_DEADLINE = () => {
            if (document.getElementById("INPUT_TP_DEADLINE").value) return document.getElementById("INPUT_TP_DEADLINE").value
            else if (document.getElementById("deadNov").checked === true) return "November 30"
            else if (document.getElementById("deadOth").checked === true) return document.getElementById("INPUT_TP_DEADLINE").value
            else return ""
        }

        const INPUT_TP_NCFY = () => {
            if (document.getElementById("INPUT_TP_NCFY").value) return document.getElementById("INPUT_TP_NCFY").value
            else if (document.getElementById("TPNCY").checked === true) return ""
            else return ""
        }

        const INPUT_TP_OTHER = document.getElementById("INPUT_TP_OTHER").value




        setHandleStateOfTags(true)
        if (INPUT_REVIEWPERIOD === "") {
            dispatch(
                getNotification({
                    message: "Please fill the period under review",
                    type: "default",
                })
            );
        }
        else if (COMPANY === "") {
            dispatch(
                getNotification({
                    message: "Please fill the company name",
                    type: "default",
                })
            );
        }

        else {

            try {
                setLoaderAgreement(true);
                const FormData = require("form-data");
                const sendData = new FormData();
                sendData.append("agreement_type", "Returns Chapter");
                sendData.append("file_name", renameString);
                sendData.append("file_tags", tagStore.join(",").replaceAll(",", ";").replace(/;$/, ''));
                sendData.append("api_type_alias", "Basic");


                sendData.append("INPUT_REVIEWPERIOD", INPUT_REVIEWPERIOD);
                sendData.append("COMPANY", COMPANY);
                sendData.append("INPUT_ITR_DEADLINE", INPUT_ITR_DEADLINE());
                sendData.append("INPUT_ITR_NCFY", INPUT_ITR_NCFY());

                INPUT_ITR_OTHER && sendData.append("INPUT_ITR_OTHER", INPUT_ITR_OTHER);

                sendData.append("INPUT_TAR_NCFY", INPUT_TAR_NCFY());
                INPUT_TAR_OTHER && sendData.append("INPUT_TAR_OTHER", INPUT_TAR_OTHER);
                INPUT_WHT_PERIOD && sendData.append("INPUT_WHT_PERIOD", INPUT_WHT_PERIOD);



                INPUT_WHT_OTHER && sendData.append("INPUT_WHT_OTHER", INPUT_WHT_OTHER);
                INPUT_WHT_FINE && sendData.append("INPUT_WHT_FINE", INPUT_WHT_FINE);

                sendData.append("INPUT_TP_NA", INPUT_TP_NA());

                if (activate === false) {
                    INPUT_TP_DEADLINE() !== "November 30" && sendData.append("INPUT_TP_DEADLINE", INPUT_TP_DEADLINE());
                    INPUT_TP_NCFY() !== "" && sendData.append("INPUT_TP_NCFY", INPUT_TP_NCFY());
                    sendData.append("INPUT_TP_OTHER", INPUT_TP_OTHER);
                }

                const config = {
                    method: "post",
                    url: `${REACT_APP_CREATE_AGREEMENT_API}/ey_returns_request`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    data: sendData,
                };
                setHandleSubmit(true);

                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    const text = getResponse.data.agreement_text;
                    setCreatAgreement(text);
                    // getPlanDetails("noRefresh");
                    // console.log("Success");
                }
            } catch (err) {
                console.log(err);
                setHandleSubmit(false);
                setLoaderAgreement(false)
                // console.log("Error", err);
                dispatch(
                    getNotification({
                        message: "Sorry, our network is down with too many requests. Please try in sometime!",
                        type: "default",
                    })
                );
            }
        }
        setLoaderAgreement(false);
        setTimeout(() => preventFromCopyPaste(), 100);
    };

    const download = async () => {
        const text = document.getElementById("edit").innerText;
        try {
            const FormData = require("form-data");
            const sendData = new FormData();
            sendData.append("input_text", text);
            const config = {
                method: "post",
                url: `${REACT_APP_CREATE_AGREEMENT_API}/ey_export_as_docx`,
                responseType: "blob",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    // "access_token": UserService.getToken()
                },
                data: sendData,
            };
            setLoaderDownload(true);
            const getResponse = await axios(config);
            if (getResponse.status === 200) {
                const getCurrentDate = () => {
                    const currentDate = new Date();
                    const year = String(currentDate.getFullYear()).slice(-4);
                    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
                    const day = String(currentDate.getDate()).padStart(2, "0");
                    return `${year}-${day}-${month}`;
                };
                const blob = new Blob([getResponse.data], {
                    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = `Returns Chapter_${getCurrentDate()}.docx`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                dispatch(
                    getNotification({
                        message: "Downloaded successfully",
                        type: "success",
                    })
                );
            }
        } catch (err) {
            setLoaderDownload(false);
            console.log("Error", err);
            dispatch(
                getNotification({
                    message: "Network issue. Please click on the download button again..",
                    type: "default",
                })
            );
        }
        setLoaderDownload(false);
        setTimeout(() => preventFromCopyPaste(), 10);
    };

    const checkBoxStringMaker = (event, name) => {
        if (event.target.checked === true) {
            checkBoxString.push(name);
            setCheckBoxString([...checkBoxString]);
        } else {
            checkBoxString.pop(name);
            setCheckBoxString([...checkBoxString]);
        }
    };

    const resetForm = () => {
        fieldNames.forEach((fieldName) => {
            const field = document.getElementById(fieldName);
            if (field) {
                field.value = '';
            }
        });

        setRenameString("New File")
        let temp = []
        setTagStore(temp)
        setHandleStateOfTags(false)
    };

    const toggleSwitch = () => {
        const toggleButton = document.getElementById("toggle-button");
        toggleButton.classList.toggle("active");
        const toggleLable = toggleButton.querySelector(".toggle-button-label");

        if (toggleButton.classList.contains("active")) {
            toggleLable.classList.remove("mx-2.5");
            toggleLable.classList.add("add-margin");
        } else {
            toggleLable.classList.remove("add-margin");
            toggleLable.classList.add("mx-2.5");
        }
        toggleLable.textContent = toggleButton.classList.contains("active")
            ? "Owl"
            : "Falcon";
    };

    const getTagButtonFromArray = () => {
        const tag = document.querySelector(".custom-input")
        let tempArr = [...tagStore]
        if (tag.value === "") {
            dispatch(
                getNotification({
                    message: "Please enter a tag.",
                    type: "default",
                })
            );
        }
        else {
            tempArr.push(tag.value)
        }
        setTagStore(tempArr);
        tag.value = ""
    }

    const removeTagFromArray = (value) => {
        let tempArr = [...tagStore]
        if (value) tempArr = tempArr.filter(e => e !== value)
        setTagStore(tempArr);
    }

    const handleConfirm = () => {
        sendData();
        setPromt(false)
    };
    const handleProConfirm = () => {
        toggleSwitch()
        sendData(true)
        setPromt(false)
    }
    const handleCancel = () => {
        setPromt(false)
    };


    return (
        <div id="contract" className="container-fluid contract">
            <div className="alert alert-secondary p-0 m-0" role="alert">
                <h3 className='text-center'>Returns Chapter</h3>
            </div>
            {loaderCredit ? (
                <div style={{ marginTop: "30vh" }} className="md:col-span-2 md:mt-0">
                    <Loader
                        type="bubble-spin"
                        bgColor={"#000000"}
                        title={"Loading..."}
                        color={"#000000"}
                        size={100}
                    />
                </div>
            ) : (
                <div className="contractForm mt-1">
                    <div>
                        <div className="md:grid md:grid-cols-4 md:gap-6">
                            <div
                                style={{
                                    // height: "1353px",
                                }}
                                className={
                                    handleSubmit ? "md:col-span-2" : "md:col-span-4 container"
                                }
                            >
                                {/* <form> */}
                                <div className="shadow sm:overflow-hidden sm:rounded-md p-1">
                                    <div className="menu-bar mt-1">
                                        <button className="btn btn-success ml-3">
                                            <input
                                                id="input-file-name"
                                                className={rename ? "" : "d-none"}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") setRename(false);
                                                }}
                                                onChange={(e) => {
                                                    setRenameString(e.target.value);
                                                }}
                                                defaultValue={renameString}
                                                type="text"
                                                style={{
                                                    width: "150px",
                                                    height: "30px",
                                                    color: "black",
                                                }}
                                            />
                                            <span
                                                onClick={() => {
                                                    if (rename) setRename(false);
                                                    else setRename(true);
                                                }}
                                                id="file-name" spiketip-title={`${renameString}`} spiketip-pos={renameString.length > 20 ? "right" : "bottom"} className={rename ? "d-none" : ""}>
                                                {renameString.length > 20 ? `${renameString.slice(0, 20)}...` : renameString}
                                                <span className="ml-2">|</span>
                                            </span>
                                            <i
                                                onClick={() => {
                                                    if (rename) setRename(false);
                                                    else setRename(true);
                                                }}
                                                className={
                                                    rename ? "fas fa-check mx-3" : "fas fa-edit ml-3"
                                                }
                                            ></i>
                                            {/* <i className="fas fa-times"></i> */}
                                        </button>
                                        {/* <button
                                            id="toggle-button"
                                            className="btn toggle-button mx-2 float-end mt-1"
                                            onClick={() => {
                                                toggleSwitch()
                                            }}
                                        >
                                            <span
                                                // spiketip-title="Use PRO" spiketip-pos="bottom" 
                                                className="toggle-button-label fw-bolder mx-2.5">
                                                Falcon
                                            </span>
                                        </button> */}
                                        <button
                                            spiketip-title="Reset Form" spiketip-pos="bottom"
                                            onClick={() => {
                                                resetForm();
                                            }}
                                            className="btn btn-dark float-end mr-4"
                                        >
                                            <i className="fas fa-redo"></i>
                                        </button>
                                        <button
                                            spiketip-title={handleSubmit ? `${tagStore.length} tag${tagStore.length >= 2 ? "s" : ""} added.` : "Add Tag"} spiketip-pos="bottom"
                                            onClick={() => getTagButtonFromArray()}
                                            id="add-tag"
                                            className="btn btn-dark round-btn mx-2 mt-0.5 float-end"
                                        >
                                            {handleSubmit ?
                                                <span className="-mx-2 fw-bolder">
                                                    {tagStore.length}
                                                </span> :
                                                <i className="fas fa-plus plus-position"></i>
                                            }
                                        </button>
                                        <input
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    getTagButtonFromArray(e.target.value)
                                                }
                                            }}
                                            placeholder="Add tag here"
                                            className={handleStateOfTags ? "d-none" : "px-1 mt-1.5 rounded btn custom-input float-end"}
                                            style={{
                                                height: "30px",
                                                // fontSize: handleSubmit ? "14px" : "",
                                                width: "110px",
                                                border: "2px solid",
                                                textAlign: "left",
                                            }}
                                            type="text"
                                        />
                                        <span className="scrollable-span mx-2 mt-2 float-end pb-1">
                                            {tagStore.length > 0 &&
                                                !handleSubmit &&
                                                tagStore.map((ele, ind) => (
                                                    <button
                                                        key={ind}
                                                        className="btn btn-dark ml-2 p-0 px-1 position-relative"
                                                        onMouseEnter={(e) => {
                                                            const iconElement = e.target.querySelector('i');
                                                            iconElement && iconElement.classList.remove("d-none")
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            const iconElement = e.target.querySelector('i');
                                                            iconElement && iconElement.classList.add("d-none")
                                                        }}
                                                    >
                                                        {ele}
                                                        <i
                                                            // style={{ display: "none" }}
                                                            onClick={() => { removeTagFromArray(ele) }}
                                                            className="fas fa-window-close ml-1 d-none"></i>
                                                    </button>
                                                ))}
                                        </span>
                                    </div>
                                    <hr className="mt-2 mb-0" />
                                    <div className="space-y-6 bg-white sm:p-4">
                                        <div className="grid grid-cols-3 gap-1">
                                            {formdata && formdata.map((e, i) => (
                                                <div key={i} className={"col-span-6 sm:col-span-3"}>
                                                    <label htmlFor={e.id} className={`block text-lg font-medium text-primary ${e.deactive ? "deactive-label" : ""}`}>
                                                        {e.name} <span className={`${e.required ? "text-danger" : "d-none"}`}>*</span>
                                                    </label>
                                                    {
                                                        e.group && e.group.map((ele, ind) => (
                                                            <div key={ind} className={`col-span-6 sm:col-span-3 px-1 my-2 ${activate && ele.active ? "d-none" : ""}`}>
                                                                <label htmlFor={ele.id} className={`block text-sm font-medium text-black}`}>
                                                                    {ele.name} <span className={`${ele.required ? "text-danger" : "d-none"}`}>*</span>
                                                                </label>
                                                                {ele.element && < input
                                                                    type={ele.type ? ele.type : "text"}
                                                                    placeholder={ele.placeholder}
                                                                    name={ele.nameAtt}
                                                                    id={ele.id}
                                                                    autoComplete="given-name"
                                                                    className={`${ele.type === "radio" ? "mt-1 block shadow-sm sm:text-sm" : "mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-grren focus:ring-indigo-500 sm:text-sm"} ${activate && ele.active ? "border-gray-300" : ""}`}
                                                                    disabled={activate && ele.active}
                                                                />}
                                                                {
                                                                    ele.group && ele.group.map((ele2, ind2) => (
                                                                        <div key={ind2} className={`col-span-6 sm:col-span-3 ${ele2.type === "radio" || ele2.type === "checkbox" ? "flex items-center" : ""} px-2 my-2`}>
                                                                            {ele2.type === "text" &&
                                                                                <label htmlFor={ele2.id}
                                                                                    className={`block text-sm font-medium text-black ${activate && ele2.active ? "text-secondary" : ""}`}>
                                                                                    {ele2.name} <span className={`${ele2.required ? "text-danger" : "d-none"}`}>*</span>
                                                                                </label>}
                                                                            <input
                                                                                type={ele2.type ? ele2.type : "text"}
                                                                                placeholder={ele2.placeholder}
                                                                                name={ele2.nameAtt}
                                                                                id={ele2.id}
                                                                                disabled={ele2.disabled || (activate && ele2.active)}
                                                                                autoComplete="given-name"
                                                                                className={`${ele2.type === "radio" || ele2.type === "checkbox" ? "mb-1.5 block shadow-sm sm:text-sm mx-2" : "mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"} ${ele2.disabled || (activate && ele2.active) ? "border-gray-300" : ""}`}
                                                                                onChange={(event) => {
                                                                                    const value = event.target.id
                                                                                    if (value === "oth") {
                                                                                        document.getElementById("INPUT_ITR_DEADLINE").disabled = false
                                                                                        document.getElementById("INPUT_ITR_DEADLINE").classList.remove("border-gray-300")
                                                                                    }

                                                                                    else if (value === ("nov" || "sep")) {
                                                                                        document.getElementById("INPUT_ITR_DEADLINE").disabled = true
                                                                                        document.getElementById("INPUT_ITR_DEADLINE").classList.add("border-gray-300")
                                                                                    }
                                                                                    else if (value === "itrNCY") {
                                                                                        if (event.target.checked === true) {
                                                                                            document.getElementById("INPUT_ITR_NCFY").disabled = true
                                                                                            document.getElementById("INPUT_ITR_NCFY").classList.add("border-gray-300")
                                                                                        }
                                                                                        else {
                                                                                            document.getElementById("INPUT_ITR_NCFY").disabled = false
                                                                                            document.getElementById("INPUT_ITR_NCFY").classList.remove("border-gray-300")
                                                                                        }
                                                                                    }
                                                                                    else if (value === "tarNCYear") {
                                                                                        if (event.target.checked === true) {
                                                                                            document.getElementById("INPUT_TAR_NCFY").disabled = true
                                                                                            document.getElementById("INPUT_TAR_NCFY").classList.add("border-gray-300")
                                                                                        }
                                                                                        else {
                                                                                            document.getElementById("INPUT_TAR_NCFY").disabled = false
                                                                                            document.getElementById("INPUT_TAR_NCFY").classList.remove("border-gray-300")
                                                                                        }
                                                                                    }

                                                                                    else if (value === "TPNCY") {
                                                                                        if (event.target.checked === true) {
                                                                                            document.getElementById("INPUT_TP_NCFY").disabled = true
                                                                                            document.getElementById("INPUT_TP_NCFY").classList.add("border-gray-300")
                                                                                        }
                                                                                        else {
                                                                                            document.getElementById("INPUT_TP_NCFY").disabled = false
                                                                                            document.getElementById("INPUT_TP_NCFY").classList.remove("border-gray-300")
                                                                                        }
                                                                                    }
                                                                                    else if (value === "deadOth") {
                                                                                        document.getElementById("INPUT_TP_DEADLINE").disabled = false
                                                                                        document.getElementById("INPUT_TP_DEADLINE").classList.remove("border-gray-300")
                                                                                    }

                                                                                    else if (value === "deadNov") {
                                                                                        document.getElementById("INPUT_TP_DEADLINE").disabled = true
                                                                                        document.getElementById("INPUT_TP_DEADLINE").classList.add("border-gray-300")
                                                                                    }
                                                                                    else if (value === "TPYes") {
                                                                                        setActivate(false)
                                                                                        document.getElementById("INPUT_TP_NA").disabled = true
                                                                                        document.getElementById("INPUT_TP_NA").classList.add("border-gray-300")
                                                                                    }
                                                                                    else if (value === "TPNo") {
                                                                                        document.getElementById("INPUT_TP_NA").disabled = false
                                                                                        document.getElementById("INPUT_TP_NA").classList.remove("border-gray-300")
                                                                                        setActivate(true)
                                                                                    }

                                                                                }}
                                                                            />
                                                                            {ele2.type === "radio" &&
                                                                                <label htmlFor={ele2.id} className={`block text-sm font-medium text-black ${activate && ele2.active ? "text-secondary" : ""}`}>
                                                                                    {ele2.name} <span className={`${ele2.required ? "text-danger" : "d-none"}`}>*</span>
                                                                                </label>
                                                                            }
                                                                            {ele2.type === "checkbox" &&
                                                                                <label htmlFor={ele2.id} className={`block text-sm font-medium text-black ${activate && ele2.active ? "text-secondary" : ""}`}>
                                                                                    {ele2.name} <span className={`${ele2.required ? "text-danger" : "d-none"}`}>*</span>
                                                                                </label>
                                                                            }
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            {promt && <Popup
                                                message1="Would you like to go ahead and generate the chapter?"
                                                mode={false}
                                                onConfirm={handleConfirm}
                                                onProConfirm={handleProConfirm}
                                                onCancel={handleCancel} />}
                                            <button
                                                spiketip-title="Submit Form" spiketip-pos="top"
                                                type="submit"
                                                className="btn btn-success mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                onClick={() => {
                                                    {
                                                        setPromt(true)
                                                    }
                                                }}
                                            >
                                                Submit
                                                <i className="fas fa-upload mx-2"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* </form> */}
                            </div>
                            {loaderAgreement ? (
                                <div
                                    id="my-agreement-spinner"
                                    style={{ marginTop: "30vh" }}
                                    className="md:col-span-2 md:mt-0"
                                >
                                    <Loader
                                        type="bubble-ping"
                                        bgColor={"#000000"}
                                        title={
                                            "Loading.."
                                        }
                                        color={"#000000"}
                                        size={100}
                                    />
                                    <br />
                                    <br />
                                    {/* <h5 className={document.getElementById("toggle-button").classList.contains("active") ? "d-none" : "font-italic text-center mt-5"}> The strongest of all warriors are these two - time and patience</h5>
                  <br />
                  <figcaption className={document.getElementById("toggle-button").classList.contains("active") ? "d-none" : "blockquote-footer mb-0 font-italic text-end"}>
                    Mark Twain
                  </figcaption> */}

                                    <h5 className="text-center mt-5">Warning: This is a preliminary and work-in-progress Scripter prepared exclusively for the purpose of POC. This is a concept demonstration Scripter and does not represent the final features,design or functionality of the product. The Scripter is to be used for the limited purpose of testing and you may not rely on this for generating reports in live client matters such as advisory or due diligence work. </h5>
                                </div>
                            ) : loaderDownload ? (
                                <div
                                    style={{ marginTop: "30vh" }}
                                    className="md:col-span-2 md:mt-0"
                                >
                                    <Loader
                                        type="box-rotate-z"
                                        bgColor={"#000000"}
                                        title={"Downloading.."}
                                        color={"#000000"}
                                        size={50}
                                    />
                                </div>
                            ) : (
                                <div
                                    className={handleSubmit ? "md:col-span-2 md:mt-0" : "d-none"}
                                >
                                    <div className="sm:px-1 shadow sm:overflow-hidden sm:rounded-md py-1 px-1">
                                        <div className="menu-bar mt-1">
                                            <span className="scrollable-span-right pb-1">
                                                {tagStore.length > 0 &&
                                                    handleSubmit && handleStateOfTags &&
                                                    tagStore.map((ele, ind) => (
                                                        <button key={ind} className="btn btn-dark ml-1 p-0 px-1">
                                                            {ele}
                                                        </button>
                                                    ))}
                                            </span>
                                            <button
                                                spiketip-title={contentState ? "Edit Content" : "Save Conetent"} spiketip-pos="bottom"
                                                className="btn btn-secondary mx-4"
                                                onClick={() => {

                                                    setContentState(!contentState);
                                                    setTimeout(() => preventFromCopyPaste(), 10);
                                                    if (contentState) {
                                                        document.getElementById(
                                                            "edit"
                                                        ).contentEditable = true;
                                                        document
                                                            .getElementById("download")
                                                            .setAttribute("disabled", true);
                                                    } else {
                                                        document.getElementById(
                                                            "edit"
                                                        ).contentEditable = false;
                                                        document
                                                            .getElementById("download")
                                                            .removeAttribute("disabled");
                                                    }

                                                    // else dispatch(
                                                    //   getNotification({
                                                    //     message:
                                                    //       "Plesae sign up or log in to edit the document.",
                                                    //     type: "info",
                                                    //   })
                                                    // );
                                                }}
                                            >
                                                <i
                                                    id="icon"
                                                    className={
                                                        contentState ? "fas fa-edit" : "fas fa-save"
                                                    }
                                                ></i>
                                            </button>
                                            <button
                                                spiketip-title="Download Chapter" spiketip-pos="bottom"
                                                id="download"
                                                title="Download Chapter"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    download();
                                                    // else dispatch(
                                                    //   getNotification({
                                                    //     message:
                                                    //       "Plesae sign up or log in to download the document.",
                                                    //     type: "info",
                                                    //   })
                                                    // );
                                                }}
                                            >
                                                <i className="fas fa-download"></i>
                                            </button>
                                        </div>
                                        <hr className="mt-2" />

                                        <div className="px-3">
                                            <div
                                                id="edit"
                                                contentEditable={false}
                                                style={{
                                                    height: activate ? "1367px" : "1723px",
                                                    overflow: "auto",
                                                }}
                                                className="mt-1 text-lg text-gray-600 whitespace-pre-wrap px-1"
                                            >
                                                {
                                                    createAgreement && createAgreement.map((ele, ind) => (
                                                        <div className="" key={ind}>
                                                            <div className={ele.content_name.includes("HEADING") ? "text-xl" : "d-none"}><strong>{ele.content_text}</strong></div>
                                                            <div className={ele.content_name.includes("HEADING") ? "d-none" : "px-2 my-2"} style={{ fontSize: "15px" }}>{ele.content_text}</div>
                                                        </div>
                                                    ))
                                                }
                                                <br />
                                            </div>
                                            <button
                                                spiketip-title="Download Chapter" spiketip-pos="top"
                                                className={`btn btn-success mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mb-3`}
                                                onClick={() => {
                                                    download();
                                                }}
                                            >
                                                Download Report{" "}
                                                <i className="fas fa-download mx-1"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <br /><br /><br /><br /><br /><br /><br /><br /><br />
                        </div>
                    </div>
                </div>
            )
            }
        </div >
    );
};

export default ReturnChapter;
